<template>
	<div class="tournament">
		<SubHeader title="ODO Tournament" subtitle="ODOトーナメント" />

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="/tournament/2023"> 2023年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);"> 2022年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/tournament/2021"> 2021年度 </a>
				</li>
			</ul>

			<div class="row justify-content-center">
				<div class="col-lg-12 mb-4">
					<h2>ODO Darts Tournament 2022</h2>
					<img src="/images/tournament/2022/tournament.jpg" class="img-fluid" />
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>メンズ&nbsp;シングルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2022/result/mens_singles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(1)"
						/>
						<span class="name">脇 純平（一般）</span>
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2022/result/mens_singles_winner.jpg"
							class="img-fluid"
							@click="openGallery(0)"
						/>
						<span class="name">小川 祐一郎（神奈川）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/mens_singles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(2)"
						/>
						<span class="name">磯和 勇希（大阪）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/mens_singles_4th.jpg"
							class="img-fluid"
							@click="openGallery(3)"
						/>
						<span class="name">林 雄太（奈良）</span>
					</p>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>レディース&nbsp;シングルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2022/result/ladies_singles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(5)"
						/>
						<span class="name">青木 まゆ（神奈川）</span>
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2022/result/ladies_singles_winner.jpg"
							class="img-fluid"
							@click="openGallery(4)"
						/>
						<span class="name">林 桃加（一般）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/ladies_singles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(6)"
						/>
						<span class="name">福島 清子（京都）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/ladies_singles_4th.jpg"
							class="img-fluid"
							@click="openGallery(7)"
						/>
						<span class="name">安田 久美（全東京）</span>
					</p>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>オープン&nbsp;ダブルス</h4>
				</div>
				<div class="col-lg-3 col-6 text-center offset-lg-1">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/tournament/2022/result/open_doubles_2nd.jpg"
							class="img-fluid"
							@click="openGallery(9)"
						/>
						<span class="name">恵島 隼人（兵庫）・関口 大輝（兵庫）</span>
						<br /><br />
					</p>
				</div>
				<div class="col-lg-4 col-6 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/tournament/2022/result/open_doubles_winner.jpg"
							class="img-fluid"
							@click="openGallery(8)"
						/>
						<span class="name">田原 駿也（大阪）・磯和 勇希（大阪）</span
						><br /><br />
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/open_doubles_3rd.jpg"
							class="img-fluid"
							@click="openGallery(10)"
						/>
						<span class="name">中村 周作 （神奈川）・林 雄太（奈良）</span>
					</p>
				</div>
				<div class="col-lg-2 col-6 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/tournament/2022/result/open_doubles_4th.jpg"
							class="img-fluid"
							@click="openGallery(11)"
						/>
						<span class="name">長谷川 高志 （兵庫）・弥源治 渉（兵庫）</span>
					</p>
				</div>
			</div>

			<div class="row mb-4">
				<div class="col-lg-8 col-12 mb-3">
					<h5 class="mb-4">開催情報</h5>
					<dl class="row">
						<dt class="col-sm-1">日時</dt>
						<dd class="col-sm-11">
							<span style="font-size: 1.2rem">2022年10月10日（月祝）</span>
							<br />
							チェックイン：9:15〜、開会式：10:00〜、競技開始：10:15〜
						</dd>
						<dt class="col-sm-1">場所</dt>
						<dd class="col-sm-11">
							<span style="font-size: 1rem"
								>公益法人 堺市産業振興センター イベントホール</span
							><br />
							〒591-8025 大阪府堺市北区長曽根町183-5<br />
							<a href="https://www.sakai-ipc.jp/" target="_blank"
								>https://www.sakai-ipc.jp/</a
							>
						</dd>
						<dt class="col-sm-1">主催</dt>
						<dd class="col-sm-11">大阪ダーツオーガニゼイション</dd>
						<dt class="col-sm-1">協賛</dt>
						<dd class="col-sm-11">
							G.S.D、nakka.com、千雅、有限会社FarEast、スキマ産業、L-style、EL
							RODEO
						</dd>
						<dt class="col-sm-1">協力</dt>
						<dd class="col-sm-11">
							茨木ダーツクラブ、東大阪ダーツクラブ、Japan Darts
							Organizations、JDO各DO
						</dd>
						<dt class="col-sm-1">協力店</dt>
						<dd class="col-sm-11">
							SELFISH、Hugglife、DUETT、BULL、Harrow's、LEAFLET、BALABUSHKA、わんすもあー、
							Darts Bar JADE、Darts Bar Famous、 現場ar、Bar
							Eau-de-Vie、KING's、max OSAKA・心斎橋、 Public Bar Stinger、Darts
							House Reve、KITCHEN ＆ BAR O2、HIDE OUT、お好み焼き だん
						</dd>
						<dt class="col-lg-1"></dt>
						<dd class="col-lg-5">
							<a
								class="btn btn-download"
								href="https://www.facebook.com/odo.or.jp/posts/pfbid0PoSAdztiRq1XXzhpm79jbjAsGBEGs9y2TXLdFJoLHZE1MfHKUEoBht1NhLPEJH9sl"
								target="_blank"
								><i class="far fa-file-pdf mr-2"></i>
								開催要項・ポスター（facebookページ）</a
							><br />
							<a
								class="btn btn-download"
								href="https://drive.google.com/drive/folders/1EZQPddx6Q50Us_Y2OQ4s6uKJMeO3URby"
								target="_blank"
								><i class="fas fa-video mr-2"></i>
								n01アプリ操作ガイド・マーカー解説動画（mp4）</a
							>
						</dd>
					</dl>
				</div>
				<div class="col-lg-4 col-12 mb-3">
					<img src="/images/tournament/2022/poster.jpg" class="img-fluid" />
				</div>
			</div>

			<div class="row">
				<div class="col-lg-8 col-12 mb-3">
					<h5 class="mb-2">エントリーリスト</h5>
					<div class="table-responsive mb-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">会員番号</th>
									<th scope="col" class="text-nowrap">名前</th>
									<th scope="col" class="text-nowrap">フリガナ</th>
									<th scope="col" class="text-nowrap">性別</th>
									<th scope="col" class="text-nowrap">ペア</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(player, index) in entries">
									<tr v-if="player.member_id != ''" :key="'rank' + index">
										<td class="text-nowrap">{{ index + 1 }}</td>
										<td class="text-nowrap">{{ player.member_id }}</td>
										<td class="text-nowrap score">
											{{ player.player_name_sei }} {{ player.player_name_mei }}
										</td>
										<td class="text-nowrap score">
											{{ player.player_kana_sei }} {{ player.player_kana_mei }}
										</td>
										<td class="text-nowrap score">{{ player.player_sex }}</td>
										<td class="text-nowrap score">{{ player.doubles_id }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-lg-4 col-12 mb-3"></div>
			</div>

			<LightBox
				ref="lightbox"
				:media="media"
				:show-light-box="false"
				:show-caption="true"
			></LightBox>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

Vue.use(Loading);

export default {
	name: "tournament2020",
	components: {
		SubHeader,
		LightBox,
	},
	data() {
		return {
			isLoading: true,
			entries: [],
			media: [
				{
					thumb: "/images/tournament/2022/result/mens_singles_winner.jpg",
					src: "/images/tournament/2022/result/mens_singles_winner.jpg",
					caption: "小川 祐一郎（神奈川）",
				},
				{
					thumb: "/images/tournament/2022/result/mens_singles_2nd.jpg",
					src: "/images/tournament/2022/result/mens_singles_2nd.jpg",
					caption: "脇 純平（一般）",
				},
				{
					thumb: "/images/tournament/2022/result/mens_singles_3rd.jpg",
					src: "/images/tournament/2022/result/mens_singles_3rd.jpg",
					caption: "磯和 勇希（大阪）",
				},
				{
					thumb: "/images/tournament/2022/result/mens_singles_4th.jpg",
					src: "/images/tournament/2022/result/mens_singles_4th.jpg",
					caption: "林 雄太（奈良）",
				},

				{
					thumb: "/images/tournament/2022/result/ladies_singles_winner.jpg",
					src: "/images/tournament/2022/result/ladies_singles_winner.jpg",
					caption: "林 桃加（一般）",
				},
				{
					thumb: "/images/tournament/2022/result/ladies_singles_2nd.jpg",
					src: "/images/tournament/2022/result/ladies_singles_2nd.jpg",
					caption: "青木 まゆ（神奈川）",
				},
				{
					thumb: "/images/tournament/2022/result/ladies_singles_3rd.jpg",
					src: "/images/tournament/2022/result/ladies_singles_3rd.jpg",
					caption: "福島 清子（京都）",
				},
				{
					thumb: "/images/tournament/2022/result/ladies_singles_4th.jpg",
					src: "/images/tournament/2022/result/ladies_singles_4th.jpg",
					caption: "安田 久美（全東京）",
				},

				{
					thumb: "/images/tournament/2022/result/open_doubles_winner.jpg",
					src: "/images/tournament/2022/result/open_doubles_winner.jpg",
					caption: "田原 駿也（大阪）・磯和 勇希（大阪）",
				},
				{
					thumb: "/images/tournament/2022/result/open_doubles_2nd.jpg",
					src: "/images/tournament/2022/result/open_doubles_2nd.jpg",
					caption: "恵島 隼人（兵庫）・関口 大輝（兵庫）",
				},
				{
					thumb: "/images/tournament/2022/result/open_doubles_3rd.jpg",
					src: "/images/tournament/2022/result/open_doubles_3rd.jpg",
					caption: "中村 周作 （神奈川）・林 雄太（奈良）",
				},
				{
					thumb: "/images/tournament/2022/result/open_doubles_4th.jpg",
					src: "/images/tournament/2022/result/open_doubles_4th.jpg",
					caption: "長谷川 高志 （兵庫）・弥源治 渉（兵庫）",
				},
			],
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {};
			this.axios
				.get(
					"https://script.google.com/macros/s/AKfycbxO7i0U9KXSUjIg87cQwY_Jj_nrAK7cyLXmts-2dJJv7SMxPaZNRjJqlzjlvs4xRaEB/exec",
					{
						params: params,
					}
				)
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.entries = res.data.list;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
a {
	text-decoration: underline;
}
.container {
	padding: 5rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 1rem;
	color: #333;
	border: none;
	margin-top: 1rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
